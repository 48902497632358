.total-amount {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #37474F !important;
  color: white !important;
}

.toll-transaction {
  font-size: 10px !important;
}

.toll-location {
  font-size: 13px !important;
}

.toll-amount {
  font-size: 13px !important;
}

.toll-agency {
  font-size: 10px !important;
}